import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  XMarkIcon,
  ArrowRightIcon,
  CurrencyDollarIcon,
  ClockIcon,
  MapPinIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';

const RouteInfoPanel = ({ route, schedules, pickupDropoff, onClose, companySlug }) => {
  const { pickup, dropoff } = pickupDropoff || {};
  const [routeNote, setRouteNote] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [travelDate, setTravelDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [passengers, setPassengers] = useState(1);
  const navigate = useNavigate();

  const pickupLocation = pickup ? pickup.effective_pickup : 'N/A';
  const dropoffLocation = dropoff ? dropoff.effective_dropoff : 'N/A';
  const isPickupConfigured = pickup?.is_active === 1 && pickup.admin_pickup;
  const isDropoffConfigured = dropoff?.is_active === 1 && dropoff.admin_dropoff;

  useEffect(() => {
    if (!route) return;
    const fetchRouteNote = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL || ''}/route-notes/${route.routeId}`
        );
        const data = await res.json();
        if (data?.note) setRouteNote(data.note);
      } catch (error) {
        console.error('Error fetching route note:', error);
      }
    };
    fetchRouteNote();
  }, [route]);

  if (!route) return null;

  const handleConfirm = () => {
    const selectedSchedule = schedules.find((s) => s.schedule_time === selectedTime);
  
    const scheduleTime = selectedSchedule?.schedule_time || 'N/A';
    const scheduleId = selectedSchedule?.schedule_id || null;
  
    const purchaseData = {
      routeId: route.routeId,
      linkId: route.linkId,
      scheduleId,               // ✅ Este va a la BD
      scheduleTime,             // ✅ Este se muestra en el resumen
      travelDate,
      returnDate: returnDate || null,
      passengers,
      routeName: route.routeName,
      toName: route.linkName,
      price: route.price,
    };
  
    navigate('/checkout', { state: { purchaseData, companySlug } });
  };
  

  const handleClose = () => {
    if (showForm) {
      setShowForm(false);
    } else {
      onClose();
    }
  };

  return (
    <div className="fixed inset-y-0 right-0 w-full sm:w-96 bg-white shadow-2xl transform transition-transform duration-300 ease-in-out z-50 translate-x-0">
      <div className="h-full flex flex-col">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center bg-gradient-to-r from-blue-50 to-white">
          <h3 className="text-xl font-bold text-gray-900">Route Information</h3>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <div className="flex-grow p-6 overflow-y-auto">
          <div className="space-y-6">
            {!showForm && routeNote && (
              <div className="border-l-4 border-blue-800 bg-blue-50 p-4 text-sm text-blue-900 flex items-start gap-2 rounded-md">
                <InformationCircleIcon className="h-5 w-5 mt-0.5" />
                <p>{routeNote}</p>
              </div>
            )}

            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <h4 className="text-lg font-semibold text-gray-800 mb-3">Details</h4>
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <ArrowRightIcon className="h-5 w-5 text-gray-500" />
                    <span className="text-gray-600 font-medium">From:</span>
                  </div>
                  <p className="text-gray-900 font-semibold">{route.routeName}</p>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <ArrowRightIcon className="h-5 w-5 text-gray-500" />
                    <span className="text-gray-600 font-medium">To:</span>
                  </div>
                  <p className="text-gray-900 font-semibold">{route.linkName}</p>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <CurrencyDollarIcon className="h-5 w-5 text-gray-500" />
                    <span className="text-gray-600 font-medium">Price:</span>
                  </div>
                  <p className="text-gray-900 font-semibold">${route.price}</p>
                </div>
              </div>
            </div>

            {!showForm && (
              <>
                <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                  <h4 className="text-lg font-semibold text-gray-800 mb-1">Available Schedules</h4>
                  <p className="text-sm text-gray-500 mb-3">Horarios de salida disponibles para esta ruta</p>
                  {schedules && schedules.length > 0 ? (
                    <ul className="space-y-2">
                      {schedules.map((schedule, index) => (
                        <li key={index} className="flex items-center space-x-2">
                          <ClockIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-700">{schedule.schedule_time}</span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-gray-500">No schedules available for this route.</p>
                  )}
                </div>

                <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
                  <h4 className="text-lg font-semibold text-gray-800 mb-1">Pickup/Dropoff Information</h4>
                  <p className="text-sm text-gray-500 mb-3">Pickup and dropoff locations for this route</p>
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-md font-semibold text-gray-700 mb-2">Pickup</h5>
                      {pickup ? (
                        <div className="space-y-2">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                              <MapPinIcon className="h-5 w-5 text-gray-500" />
                              <span className="text-gray-600 font-medium">Location:</span>
                            </div>
                            <p className="text-gray-900 font-semibold">{pickupLocation}</p>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                              <MapPinIcon className="h-5 w-5 text-gray-500" />
                              <span className="text-gray-600 font-medium">Source:</span>
                            </div>
                            <p className="text-gray-900 font-semibold">
                              {isPickupConfigured ? 'Configured' : 'Default'}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <p className="text-gray-500">Pickup point has not been specified. Please be ready at the main road near <span className='font-semibold'>{route.routeName}</span>.</p>
                      )}
                    </div>

                    <div>
                      <h5 className="text-md font-semibold text-gray-700 mb-2">Dropoff</h5>
                      {dropoff ? (
                        <div className="space-y-2">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                              <MapPinIcon className="h-5 w-5 text-gray-500" />
                              <span className="text-gray-600 font-medium">Location:</span>
                            </div>
                            <p className="text-gray-900 font-semibold">{dropoffLocation}</p>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                              <MapPinIcon className="h-5 w-5 text-gray-500" />
                              <span className="text-gray-600 font-medium">Source:</span>
                            </div>
                            <p className="text-gray-900 font-semibold">
                              {isDropoffConfigured ? 'Configured' : 'Default'}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <p className="text-gray-500">Dropoff point is not defined. The driver will drop you off along the main road near <span className='font-semibold'>{route.linkName}</span>.</p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {showForm && (
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm space-y-4">
                <h4 className="text-lg font-semibold text-gray-800 mb-1">Trip Details</h4>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">When are you traveling?</label>
                    <input
                      type="date"
                      className="w-full border px-3 py-2 rounded-lg"
                      value={travelDate}
                      onChange={(e) => setTravelDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Select departure time</label>
                    <select
                      className="w-full border px-3 py-2 rounded-lg"
                      value={selectedTime}
                      onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="">Select time</option>
                      {schedules?.map((s, index) => (
                        <option key={index} value={s.schedule_time}>{s.schedule_time}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Return date (optional)</label>
                    <input
                      type="date"
                      className="w-full border px-3 py-2 rounded-lg"
                      value={returnDate}
                      onChange={(e) => setReturnDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">How many passengers?</label>
                    <input
                      type="number"
                      className="w-full border px-3 py-2 rounded-lg"
                      min="1"
                      value={passengers}
                      onChange={(e) => setPassengers(Number(e.target.value))}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="p-6 border-t border-gray-200 flex justify-between gap-4">
          <button
            onClick={handleClose}
            className="w-1/2 px-4 py-3 bg-gray-200 text-gray-700 font-semibold rounded-full shadow hover:bg-gray-300 transition-colors duration-300"
          >
            Close
          </button>
          {!showForm ? (
            <button
              onClick={() => setShowForm(true)}
              className="w-1/2 px-4 py-3 bg-blue-800 text-white font-semibold rounded-full shadow hover:bg-blue-900 transition-colors duration-300"
            >
              Reserve
            </button>
          ) : (
            <button
              onClick={handleConfirm}
              disabled={!travelDate || passengers < 1 || !selectedTime}
              className="w-1/2 px-4 py-3 bg-blue-800 text-white font-semibold rounded-full shadow hover:bg-blue-900 transition-colors duration-300 disabled:opacity-50"
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RouteInfoPanel;