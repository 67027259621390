import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import ArchiveRoutes from './components/ArchiveRoutes';
import Checkout from './components/Checkout';
import ContactUs from './components/ContactUs';
import ForgotPassword from './components/shared/ForgotPassword';
import HelpCenter from './components/HelpCenter';
import LandingPage from './components/LandingPage';
import LogIn from './components/LogIn';
import MyAccount from './components/MyAccount';
import PrivacyPolicy from './components/PrivacyPolicy';
import ResetPassword from './components/shared/ResetPassword';
import RoutesPage from './components/AvailableRoutes';
import Schedules from './components/Schedules';
import SearchResults from './components/SearchResults';
import Security from './components/Security';
import SignIn from './components/SignIn';
import Support from './components/Support';
import TermsAndConditions from './components/TermsAndConditions';
import ThankYou from './components/ThankYou';
import CompanyLayout from './components/global-layout/CompanyLayout';
import { CompanyProvider } from './components/context/CompanyContext';
import { UserContext, UserProvider } from './components/context/UserContext';

// Componente de ruta protegida
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && !user) {
      navigate('/log-in', { state: { from: location } });
    }
  }, [user, loading, navigate, location]);

  if (loading) return <div className="text-center py-12">Cargando...</div>;

  return user ? children : null;
};

// Wrapper del Checkout para validar los datos
const CheckoutWrapper = () => {
  const { state } = useLocation();
  const { purchaseData } = state || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!purchaseData) {
      navigate('/search-results');
    }
  }, [purchaseData, navigate]);

  if (!purchaseData) {
    return <div className="text-center py-12">Redirigiendo...</div>;
  }

  return <Checkout purchaseData={purchaseData} />;
};

// Página de error 404
const NotFound = () => {
  const location = useLocation();

  return (
    <div className="text-center py-12">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">404 - Página no encontrada</h1>
      <p>Ruta no encontrada: {location.pathname}</p>
      <p className="mt-4">
        <Link to="/" className="text-blue-600 hover:underline">
          Volver a la página principal
        </Link>
      </p>
    </div>
  );
};

function App() {
  return (
    <Router>
      <UserProvider>
        <CompanyProvider>
          <Routes>
            {/* Rutas base sin companySlug en la URL */}
            <Route path="/" element={<CompanyLayout />}>
              <Route index element={<LandingPage />} />
              <Route path="archive-routes" element={<ArchiveRoutes />} />
              <Route path="checkout" element={<CheckoutWrapper />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="help-center" element={<HelpCenter />} />
              <Route path="log-in" element={<LogIn />} />
              <Route
                path="my-account"
                element={
                  <ProtectedRoute>
                    <MyAccount />
                  </ProtectedRoute>
                }
              />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="reset-password/:token" element={<ResetPassword />} />
              <Route path="routes" element={<RoutesPage />} />
              <Route path="schedules" element={<Schedules />} />
              <Route path="search-results" element={<SearchResults />} />
              <Route path="security" element={<Security />} />
              <Route path="sign-in" element={<SignIn />} />
              <Route
                path="support"
                element={
                  <ProtectedRoute>
                    <Support />
                  </ProtectedRoute>
                }
              />
              <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="thank-you" element={<ThankYou />} />
            </Route>

            {/* Ruta por defecto (404) */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </CompanyProvider>
      </UserProvider>
    </Router>
  );
}

export default App;