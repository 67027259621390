import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserContext } from '../components/context/UserContext';
import LoginForm from './LoginForm'; // Ensure this component is available
import logo from '../assets/img/transporzuma-checkout.svg';

// Load Stripe dynamically
let stripePromise;
const getStripe = async () => {
  if (!stripePromise) {
    try {
      const response = await fetch('https://admin.kuvaway.com/api/stripe-public-key');
      if (!response.ok) {
        throw new Error('Failed to fetch Stripe public key');
      }
      const { publicKey } = await response.json();
      stripePromise = loadStripe(publicKey);
    } catch (err) {
      console.error('Error fetching Stripe public key:', err);
      throw err;
    }
  }
  return stripePromise;
};

const ResetPasswordForm = ({ setShowResetPassword, setShowLogin }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://admin.kuvaway.com/api/auth/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
        setError('');
      } else {
        setError(data.message);
        setMessage('');
      }
    } catch (err) {
      setError('Failed to send request');
      setMessage('');
    }
  };

  return (
    <div className="max-w-md w-full text-center">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Reset Password</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>
        {message && <p className="text-green-600 text-sm">{message}</p>}
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <button
          type="submit"
          className="w-full px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
        >
          Send Reset Link
        </button>
      </form>
      <p className="mt-4 text-gray-600">
        <button
          type="button"
          onClick={() => {
            setShowResetPassword(false);
            setShowLogin(true);
          }}
          className="text-blue-600 underline"
        >
          Back to Login
        </button>
      </p>
    </div>
  );
};

const CheckoutForm = ({ purchaseData, subtotal, companySlug, navigate, setOrderConfirmed, setTickets, setShowLogin }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user, setUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [companyId, setCompanyId] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    country: user?.country || '',
    pickup: `You must wait for the driver at ${purchaseData.routeName || 'the route'} on the main highway`,
    dropoff: `The driver will drop you off at ${purchaseData.toName || 'your destination'} on the main highway`,
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const fetchCompanyId = async () => {
      try {
        console.log('Fetching company ID for slug:', companySlug);
        const response = await fetch(`https://admin.kuvaway.com/api/company-by-slug/${companySlug}`);
        const data = await response.json();
        if (response.ok && data.company_id) {
          setCompanyId(data.company_id);
        } else {
          setError('Could not find the company');
        }
      } catch (err) {
        setError('Error fetching the company: ' + err.message);
        console.error('Error fetching company ID:', err);
      }
    };
    if (companySlug) fetchCompanyId();
  }, [companySlug]);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        console.log('Fetching client secret with subtotal:', subtotal);
        const response = await fetch('https://admin.kuvaway.com/api/create-payment-intent', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ amount: Math.round(subtotal * 100), currency: 'usd' }),
        });
        const data = await response.json();
        if (data.clientSecret) {
          setClientSecret(data.clientSecret);
        } else {
          setError(data.details || 'Error fetching client secret');
        }
      } catch (err) {
        setError('Error connecting to the server: ' + err.message);
        console.error('Error fetching client secret:', err);
      }
    };
    fetchClientSecret();
  }, [subtotal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === 'email' && value && !user) {
      const checkEmail = async () => {
        try {
          const response = await fetch('https://admin.kuvaway.com/api/check-email', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: value }),
          });
          const data = await response.json();
          if (response.ok) {
            setEmailExists(data.exists);
          }
        } catch (err) {
          console.error('Error checking email:', err);
        }
      };
      checkEmail();
    } else if (name === 'email' && !value) {
      setEmailExists(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements || !clientSecret || !companyId) {
      setError('Missing required data to process the payment');
      return;
    }

    if (!termsAccepted) {
      setError('You must accept the terms and conditions to proceed.');
      return;
    }

    if (!user && emailExists) {
      setError('This email is already registered. Please log in to continue.');
      return;
    }

    if (!user) {
      if (!formData.password || !formData.confirmPassword) {
        setError('Please enter both password and confirm password');
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      if (formData.password.length < 8) {
        setError('Password must be at least 8 characters long');
        return;
      }
    }

    setProcessing(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          phone: formData.phone,
        },
      },
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    } else if (paymentIntent.status === 'succeeded') {
      const orderId = `ORD-${Date.now()}`;
      const passengersValue = purchaseData.passengers || 1;
      const isRoundTrip = !!purchaseData.returnDate;
      const tripType = isRoundTrip ? 'round_trip' : 'one_way';
      const quantityValue = isRoundTrip ? passengersValue * 2 : passengersValue;

      const servicesTax = 4;
      const subtotalAmount = Number(purchaseData.price) * quantityValue;
      const totalAmount = subtotalAmount + servicesTax;

      const orderData = {
        order_id: orderId,
        company_id: companyId,
        customer_id: user?.customer_id || null,
        travel_date: new Date(purchaseData.travelDate).toISOString(),
        return_date: purchaseData.returnDate ? new Date(purchaseData.returnDate).toISOString() : null,
        passengers: passengersValue,
        billing_first_name: formData.firstName,
        billing_last_name: formData.lastName,
        billing_email: formData.email,
        billing_phone: formData.phone,
        billing_country: formData.country,
        pickup_location: formData.pickup,
        dropoff_location: formData.dropoff,
        payment_method: 'stripe',
        total_amount: totalAmount,
        subtotal: subtotalAmount,
        services_tax: servicesTax,
        payment_status: 'completed',
        status: 'completed',
        items: [], // Add items array for createOrder endpoint
      };

      const orderItemsData = {
        route_id: purchaseData.routeId || null,
        link_id: purchaseData.linkId || null,
        schedule_id: purchaseData.scheduleId || null,
        schedule_time: purchaseData.scheduleTime || null, // Renamed from departureTime to schedule_time
        details_route: `${purchaseData.routeName || 'Trip'} to ${purchaseData.toName || 'Destination'} - Schedule Time: ${purchaseData.scheduleTime || 'N/A'} (${isRoundTrip ? 'Round Trip' : 'One Way'})`, // Updated to use Schedule Time
        trip_type: tripType,
        quantity: quantityValue,
        unit_price: Number(purchaseData.price),
        subtotal: subtotalAmount - 4,
        tax_amount: 4,
      };

      // Add orderItemsData to orderData.items for createOrder endpoint
      orderData.items = [orderItemsData];

      const paymentData = {
        payment_method: 'stripe',
        transaction_id: paymentIntent.id,
        amount: totalAmount,
        payment_status: 'completed',
      };

      // Log the data being sent for debugging
      console.log('orderData being sent to /api/orders:', orderData);
      console.log('orderItemsData included in orderData.items:', orderItemsData);
      console.log('paymentData being sent to /api/payments:', paymentData);

      try {
        let customerId = user?.customer_id;
        if (!user) {
          if (!formData.firstName || !formData.lastName || !formData.email) {
            setError('Please fill in all required fields (First Name, Last Name, Email)');
            setProcessing(false);
            return;
          }

          const customerData = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            password: formData.password,
          };
          const customerResponse = await fetch('https://admin.kuvaway.com/api/customers', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(customerData),
          });
          const customerResponseData = await customerResponse.json();
          if (!customerResponse.ok) {
            throw new Error(customerResponseData.error || 'Error registering the customer');
          }
          customerId = customerResponseData.customer_id;
          setUser({ ...customerData, customer_id: customerId });
        }

        orderData.customer_id = customerId;

        // Use the createOrder endpoint to handle both order and order items
        const orderResponse = await fetch('https://admin.kuvaway.com/api/orders', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...orderData, transaction_id: paymentIntent.id }),
        });
        const orderResponseData = await orderResponse.json();
        if (!orderResponse.ok) {
          throw new Error(orderResponseData.error || 'Error creating the order');
        }
        const { id: orderIdDb, tickets: orderTickets } = orderResponseData;

        // Since createOrder now handles order items and tickets, we don't need a separate call to /api/order-items
        const paymentResponse = await fetch('https://admin.kuvaway.com/api/payments', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ order_id: orderIdDb, ...paymentData }),
        });
        if (!paymentResponse.ok) {
          throw new Error('Error registering the payment');
        }

        setOrderConfirmed(true);
        setTickets(orderTickets || []);
      } catch (err) {
        setError(err.message);
        setProcessing(false);
      }
    }
  };

  const stripeElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#1f2937',
        '::placeholder': {
          color: '#9ca3af',
        },
      },
      invalid: {
        color: '#ef4444',
      },
    },
  };

  return (
    <div className="relative">
      {/* Loader */}
      {processing && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-10">
          <div className="flex flex-col items-center">
            <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-lg font-semibold text-gray-800">Processing your payment...</p>
          </div>
        </div>
      )}

      {/* Formulario, oculto mientras se procesa */}
      <form onSubmit={handleSubmit} className={`space-y-6 ${processing ? 'hidden' : 'block'}`}>
        <div className="space-y-4">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="First Name"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
            disabled={!!user}
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Last Name"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
            disabled={!!user}
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
            disabled={!!user}
          />
          {emailExists && !user && (
            <div className="mt-2 text-red-500 text-sm">
              This email is already registered.{' '}
              <button
                type="button"
                onClick={() => setShowLogin(true)}
                className="text-blue-600 underline"
              >
                Log in here
              </button>
            </div>
          )}
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Phone"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={!!user}
          />
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            placeholder="Country"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
          {!user && (
            <>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Password"
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirm Password"
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
            </>
          )}
          {/* Hidden inputs for pickup and dropoff to maintain save logic */}
          <input
            type="hidden"
            id="pickup"
            name="pickup"
            value={formData.pickup}
          />
          <input
            type="hidden"
            id="dropoff"
            name="dropoff"
            value={formData.dropoff}
          />
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-gray-800">Payment Details</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">
                Card Number
              </label>
              <CardNumberElement
                id="cardNumber"
                options={stripeElementOptions}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="cardExpiry" className="block text-sm font-medium text-gray-700">
                  Expiration Date
                </label>
                <CardExpiryElement
                  id="cardExpiry"
                  options={stripeElementOptions}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <div>
                <label htmlFor="cardCvc" className="block text-sm font-medium text-gray-700">
                  CVC
                </label>
                <CardCvcElement
                  id="cardCvc"
                  options={stripeElementOptions}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="terms"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
          />
          <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
            I agree to the{' '}
            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
              Terms and Conditions
            </a>
          </label>
        </div>

        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
        <button
          type="submit"
          disabled={!stripe || !clientSecret || !companyId || processing || (!user && emailExists) || !termsAccepted}
          className="w-full px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          {processing ? 'Processing...' : `Pay $${subtotal.toFixed(2)}`}
        </button>
      </form>
    </div>
  );
};

const Checkout = ({ purchaseData }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { companySlug } = state || {};
  const { user } = useContext(UserContext);
  const [stripeLoaded, setStripeLoaded] = useState(null);
  const [routeNotes, setRouteNotes] = useState('');
  const [showLogin, setShowLogin] = useState(user ? false : null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [tickets, setTickets] = useState([]);

  const pricePerPerson = Number(purchaseData?.price) || 0;
  const tripMultiplier = purchaseData?.returnDate ? 2 : 1;
  const tripCost = pricePerPerson * (purchaseData?.passengers || 1) * tripMultiplier;
  const servicesTax = 4;
  const subtotal = tripCost + servicesTax;

  useEffect(() => {
    const fetchRouteNotes = async () => {
      if (!purchaseData?.routeId) {
        console.log('No routeId provided, skipping fetchRouteNotes');
        return;
      }

      try {
        console.log('Fetching route notes for route_id:', purchaseData.routeId);
        const response = await fetch(`https://admin.kuvaway.com/api/route-notes/${purchaseData.routeId}`);
        const data = await response.json();
        if (response.ok) {
          setRouteNotes(data.notes || '');
        } else {
          console.error('Error fetching route notes:', data.error);
        }
      } catch (err) {
        console.error('Error fetching route notes:', err);
      }
    };

    fetchRouteNotes();
  }, [purchaseData?.routeId]);

  useEffect(() => {
    getStripe()
      .then((stripe) => setStripeLoaded(stripe))
      .catch((err) => {
        console.error('Error loading Stripe:', err);
        setStripeLoaded(false);
      });
  }, []);

  if (!purchaseData) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500 text-lg">Error: No purchase data provided</p>
        <p className="text-gray-600">Please start your booking from the search page.</p>
      </div>
    );
  }

  if (!companySlug) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500 text-lg">Error: No company slug provided</p>
        <p className="text-gray-600">Please start your booking from the company page.</p>
      </div>
    );
  }

  if (stripeLoaded === null) {
    return <div className="text-center py-12">Loading Stripe...</div>;
  }

  if (stripeLoaded === false) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500 text-lg">Error: Failed to load payment system</p>
        <p className="text-gray-600">Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col lg:flex-row">
      {/* Left side: Order Summary */}
      <div className="lg:w-1/2 bg-blue-900 text-white flex flex-col justify-center items-center p-8">
        <div className="mb-8">
          <img src={logo} alt="KUV - AWAY" className="h-12 w-auto" />
        </div>
        <div className="max-w-md w-full text-left">
          <h2 className="text-2xl font-bold mb-6">Order Summary</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-semibold mb-3">Trip Details</h3>
              <div className="space-y-2">
                <div className="flex justify-between"><span className="text-blue-200">Route</span><span className="font-medium">{purchaseData.routeName || 'N/A'}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">To</span><span className="font-medium">{purchaseData.toName || 'N/A'}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Schedule Time</span><span className="font-medium">{purchaseData.scheduleTime || 'N/A'}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Departure Date</span><span className="font-medium">{purchaseData.travelDate || 'N/A'}</span></div>
                {purchaseData.returnDate && <div className="flex justify-between"><span className="text-blue-200">Return Date</span><span className="font-medium">{purchaseData.returnDate}</span></div>}
                <div className="flex justify-between"><span className="text-blue-200">Passengers</span><span className="font-medium">{purchaseData.passengers || 1}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Trip Type</span><span className="font-medium">{purchaseData.returnDate ? 'Round Trip' : 'One Way'}</span></div>
              </div>
            </div>

            {routeNotes && (
              <div>
                <h3 className="text-lg font-semibold mb-3">Important</h3>
                <div className="space-y-2">
                  <p className="text-blue-200">{routeNotes}</p>
                </div>
              </div>
            )}

            {/* Pickup and Dropoff Section */}
            <div>
              <h3 className="text-lg font-semibold mb-3">Pickup and Dropoff</h3>
              <div className="space-y-2">
                <p className="text-blue-200">
                  <strong>Pickup:</strong> You must wait for the driver at {purchaseData.routeName || 'the route'} on the main highway
                </p>
                <p className="text-blue-200">
                  <strong>Dropoff:</strong> The driver will drop you off at {purchaseData.toName || 'the link'} on the main highway
                </p>
              </div>
            </div>

            <div className="border-t border-blue-700 pt-4">
              <h3 className="text-lg font-semibold mb-3">Price Breakdown</h3>
              <div className="space-y-2">
                <div className="flex justify-between"><span className="text-blue-200">Price per Person</span><span className="font-medium">${pricePerPerson.toFixed(2)}</span></div>
                <div className="flex justify-between"><span className="text-blue-200">Services Tax</span><span className="font-medium">${servicesTax.toFixed(2)}</span></div>
                <div className="flex justify-between text-lg font-semibold mt-2"><span>Total</span><span>${subtotal.toFixed(2)}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p className="text-lg font-bold">KUV - AWAY</p>
          <p className="text-sm text-blue-200">LAUNCH YOUR NEXT JOURNEY</p>
        </div>
      </div>

      {/* Right side: Login/Reset Password/Checkout or Confirmation Message */}
      <div className="lg:w-1/2 bg-white flex items-center justify-center p-8">
        <div className="max-w-md w-full text-center">
          {orderConfirmed ? (
            <div>
              {tickets && tickets.length > 0 && (
                <div className="mb-8">
                  <h3 className="text-lg font-semibold mb-3 text-gray-800">Your Tickets</h3>
                  <div className="space-y-4">
                    {tickets.map((ticket, index) => (
                      <div key={ticket.ticket_id} className="border border-gray-300 p-4 rounded-lg">
                        <h4 className="text-md font-semibold text-gray-800">Ticket {index + 1}</h4>
                        <div className="space-y-2">
                          <div className="flex justify-between"><span className="text-gray-600">Ticket ID</span><span className="font-medium">{ticket.ticket_id}</span></div>
                          <div className="flex justify-between"><span className="text-gray-600">Status</span><span className="font-medium">{ticket.status}</span></div>
                          <div className="flex justify-between"><span className="text-gray-600">Expires At</span><span className="font-medium">{new Date(ticket.expires_at).toLocaleString()}</span></div>
                          <div className="flex justify-center">
                            <img src={ticket.qr_code} alt={`QR Code for ${ticket.ticket_id}`} className="w-32 h-32" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <h1 className="text-3xl font-bold text-gray-800 mb-4">Booking Confirmed!</h1>
              <p className="text-gray-600 mb-6">Thank you for your purchase. Please present the QR codes above to the driver.</p>
              <a
                href={`/${companySlug}/my-tickets`}
                className="inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
              >
                View My Tickets
              </a>
            </div>
          ) : showLogin === null && !user ? (
            <div>
              <h2 className="text-2xl font-bold text-gray-800 mb-4">Do you already have an account?</h2>
              <p className="text-gray-600 mb-6">
                Having an account allows you to manage your bookings, view your travel history, and receive exclusive offers. Choose an option below to continue.
              </p>
              <div className="space-x-4">
                <button
                  onClick={() => setShowLogin(true)}
                  className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
                >
                  Yes, I have
                </button>
                <button
                  onClick={() => setShowLogin(false)}
                  className="px-6 py-3 bg-gray-200 text-gray-800 font-semibold rounded-lg hover:bg-gray-300"
                >
                  No, I don't
                </button>
              </div>
            </div>
          ) : showResetPassword ? (
            <ResetPasswordForm setShowResetPassword={setShowResetPassword} setShowLogin={setShowLogin} />
          ) : showLogin ? (
            <LoginForm
              setShowLogin={setShowLogin}
              companySlug={companySlug}
              setShowResetPassword={setShowResetPassword}
            />
          ) : (
            <Elements stripe={stripeLoaded}>
              <CheckoutForm
                purchaseData={purchaseData}
                subtotal={subtotal}
                companySlug={companySlug}
                navigate={navigate}
                setOrderConfirmed={setOrderConfirmed}
                setTickets={setTickets}
                setShowLogin={setShowLogin}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;