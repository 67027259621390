import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../components/context/UserContext';

const LogIn = () => {
  const { companySlug } = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, user, loading: isLoading } = useContext(UserContext);
  const navigate = useNavigate();

  // Redirigir si ya está autenticado
  useEffect(() => {
    if (!isLoading && user) {
      navigate(`/${companySlug}/my-account`);
    }
  }, [user, isLoading, navigate, companySlug]);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(validateEmail(value) ? '' : 'Por favor ingresa un correo válido');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    if (!email || !password) {
      setError('Correo y contraseña son obligatorios');
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setEmailError('Por favor ingresa un correo válido');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('https://admin.kuvaway.com/api/customer-login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok || data.error) {
        throw new Error(data.error || 'Correo o contraseña inválidos');
      }

      console.log('Login exitoso:', data); // para depuración
      setSuccess('Inicio de sesión exitoso');
      login(data.user, data.token);
      navigate(`/${companySlug}/my-account`);
    } catch (err) {
      console.error('LogIn - Error:', err);
      setError(err.message || 'Error al iniciar sesión');
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Cargando...</p>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Iniciar Sesión</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Correo Electrónico
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
              emailError ? 'border-red-500' : ''
            }`}
            required
            disabled={loading}
          />
          {emailError && <p className="text-sm text-red-500 mt-1">{emailError}</p>}
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Contraseña
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
            disabled={loading}
          />
        </div>
        {error && <p className="text-red-600">{error}</p>}
        {success && <p className="text-green-600">{success}</p>}
        <button
          type="submit"
          className={`w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={loading}
        >
          {loading ? 'Cargando...' : 'Iniciar Sesión'}
        </button>
      </form>
      <p className="mt-4 text-center">
        <Link to={`/forgot-password`} className="text-blue-600 hover:underline">
          ¿Olvidaste tu contraseña?
        </Link>
      </p>
      <p className="mt-2 text-center">
        ¿No tienes una cuenta?{' '}
        <Link to={`/sign-in`} className="text-blue-600 hover:underline">
          Regístrate
        </Link>
      </p>
    </div>
  );
};

export default LogIn;
