import React from 'react';
import { Dialog } from '@headlessui/react';
import { format } from 'date-fns';
import { useOutletContext } from 'react-router-dom'; // Añadido para obtener companySlug si es necesario

// SVG para el logo (puedes reemplazarlo con tu propio SVG)
const LogoSVG = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="text-blue-600"
  >
    <path
      d="M12 2L2 7L12 12L22 7L12 2Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 17L12 22L22 17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12L12 17L22 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Drawer = ({ isOpen, onClose, order, tickets }) => {
  const { companySlug } = useOutletContext(); // Añadido para acceder al companySlug desde el contexto

  if (!order) return null;

  const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : 'N/A');
  const formatTimestamp = (timestamp) => (timestamp ? new Date(timestamp).toLocaleString() : 'N/A');

  // Ejemplo: Si en el futuro quisieras agregar navegación en el botón "Download"
  const handleDownload = () => {
    // Aquí podrías implementar la lógica de descarga
    console.log(`Downloading order ${order.order_id} for companySlug: ${companySlug}`);
    // Ejemplo de navegación futura (sin companySlug en la URL):
    // navigate('/download', { state: { order, companySlug } });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-[1000]">
      <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" />
      <div className="fixed inset-y-0 right-0 max-w-full flex">
        <Dialog.Panel className="w-screen max-w-2xl bg-white shadow-2xl border-l border-gray-200 flex flex-col">
          {/* Encabezado con logo y título */}
          <div className="flex items-center justify-between p-6 border-b border-gray-200 bg-gray-50">
            <div className="flex items-center space-x-3">
              <LogoSVG />
              <h2 className="text-2xl font-bold text-gray-800">Order Summary</h2>
            </div>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 text-2xl font-semibold transition-colors"
            >
              ×
            </button>
          </div>

          {/* Contenido principal */}
          <div className="flex-1 overflow-y-auto p-8 space-y-8">
            {/* Detalles de la orden */}
            <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Order Details</h3>
              <div className="grid grid-cols-2 gap-4 text-sm text-gray-700">
                <div>
                  <p>
                    <span className="font-medium">Order ID:</span> {order.order_id}
                  </p>
                  <p>
                    <span className="font-medium">Company:</span> {order.company_id}
                  </p>
                  <p>
                    <span className="font-medium">Customer ID:</span> {order.customer_id || 'N/A'}
                  </p>
                  <p>
                    <span className="font-medium">Customer:</span> {order.billing_first_name}{' '}
                    {order.billing_last_name}
                  </p>
                  <p>
                    <span className="font-medium">Email:</span> {order.billing_email}
                  </p>
                  <p>
                    <span className="font-medium">Phone:</span> {order.billing_phone || 'N/A'}
                  </p>
                  <p>
                    <span className="font-medium">Country:</span> {order.billing_country || 'N/A'}
                  </p>
                  <p>
                    <span className="font-medium">Passengers:</span> {order.passengers}
                  </p>
                </div>
                <div>
                  <p>
                    <span className="font-medium">Travel Date:</span> {formatDate(order.travel_date)}
                  </p>
                  <p>
                    <span className="font-medium">Return Date:</span> {formatDate(order.return_date)}
                  </p>
                  <p>
                    <span className="font-medium">Created Date:</span>{' '}
                    {formatTimestamp(order.created_date)}
                  </p>
                  <p>
                    <span className="font-medium">Updated Date:</span>{' '}
                    {formatTimestamp(order.updated_date)}
                  </p>
                  <p>
                    <span className="font-medium">Status:</span>{' '}
                    <span
                      className={`inline-block px-2 py-1 text-xs rounded-full font-semibold ${
                        order.status === 'completed'
                          ? 'bg-green-100 text-green-700'
                          : order.status === 'pending'
                          ? 'bg-yellow-100 text-yellow-700'
                          : order.status === 'cancelled' || order.status === 'refunded'
                          ? 'bg-red-100 text-red-700'
                          : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Detalles de la ruta */}
            <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Route Details</h3>
              <div className="border border-gray-200 rounded-lg overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 text-sm">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-left font-semibold text-gray-700">Route</th>
                      <th className="px-4 py-3 text-left font-semibold text-gray-700">Type</th>
                      <th className="px-4 py-3 text-left font-semibold text-gray-700">Qty</th>
                      <th className="px-4 py-3 text-left font-semibold text-gray-700">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100">
                    {order.items?.length > 0 ? (
                      order.items
                        .sort((a, b) => (a.schedule_id || 0) - (b.schedule_id || 0))
                        .map((item, i) => (
                          <tr key={i}>
                            <td className="px-4 py-3 text-gray-700">{item.details_route || 'N/A'}</td>
                            <td className="px-4 py-3 capitalize text-gray-700">
                              {item.trip_type === 'round_trip' ? 'Round Trip' : 'One Way'}
                            </td>
                            <td className="px-4 py-3 text-gray-700">{item.quantity}</td>
                            <td className="px-4 py-3 text-gray-700">
                              ${Number(item.subtotal || 0).toFixed(2)}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="px-4 py-3 text-center text-gray-500">
                          No route items available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Detalles de ubicación */}
            <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Location Details</h3>
              <p className="text-sm text-gray-700">
                <span className="font-medium">Pickup Location:</span> {order.pickup_location || 'N/A'}
              </p>
              <p className="text-sm text-gray-700">
                <span className="font-medium">Dropoff Location:</span> {order.dropoff_location || 'N/A'}
              </p>
            </div>

            {/* Notas */}
            <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Notes</h3>
              <p className="text-sm text-gray-700">
                <span className="font-medium">Public Notes:</span> {order.public_notes || 'N/A'}
              </p>
              <p className="text-sm text-gray-700">
                <span className="font-medium">Private Notes:</span> {order.private_notes || 'N/A'}
              </p>
            </div>

            {/* Información de pago */}
            <div className="bg-gray-50 border border-gray-200 rounded-lg p-6 shadow-sm">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Payment Information</h3>
              <p className="text-sm text-gray-700 mb-1">
                <span className="font-medium">Method:</span> {order.payment_method || 'N/A'}
              </p>
              <p className="text-sm text-gray-700 mb-1">
                <span className="font-medium">Subtotal:</span> ${Number(order.subtotal || 0).toFixed(2)}
              </p>
              <p className="text-sm text-gray-700 mb-1">
                <span className="font-medium">Services Tax:</span> ${Number(order.services_tax || 0).toFixed(2)}
              </p>
              <p className="text-sm text-gray-700 mb-1">
                <span className="font-medium">Total Amount:</span> ${Number(order.total_amount || 0).toFixed(2)}
              </p>
              <p className="text-sm text-gray-700">
                <span className="font-medium">Status:</span>{' '}
                <span
                  className={`inline-block px-2 py-1 text-xs rounded-full font-semibold ${
                    order.payment_status === 'completed'
                      ? 'bg-green-100 text-green-700'
                      : order.payment_status === 'pending'
                      ? 'bg-yellow-100 text-yellow-700'
                      : 'bg-gray-200 text-gray-700'
                  }`}
                >
                  {(order.payment_status || 'N/A').charAt(0).toUpperCase() +
                    (order.payment_status || 'N/A').slice(1)}
                </span>
              </p>
            </div>

            {/* Tickets */}
            {tickets?.length > 0 && (
              <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">Tickets</h3>
                <div className="grid grid-cols-2 gap-4">
                  {tickets.map((ticket) => (
                    <div key={ticket.ticket_id} className="border rounded-lg p-4 text-center">
                      <p className="text-sm font-medium mb-1">{ticket.ticket_id}</p>
                      <p className="text-xs text-gray-500 mb-2">
                        <span
                          className={`inline-block px-2 py-0.5 rounded-full ${
                            ticket.status === 'pending'
                              ? 'bg-yellow-100 text-yellow-700'
                              : ticket.status === 'redeemed'
                              ? 'bg-green-100 text-green-700'
                              : 'bg-gray-200 text-gray-700'
                          }`}
                        >
                          {ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}
                        </span>
                      </p>
                      {ticket.qr_code && (
                        <img src={ticket.qr_code} alt="QR Code" className="w-24 h-24 mx-auto" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Footer con botones */}
          <div className="sticky bottom-0 bg-white border-t border-gray-200 p-6 flex justify-between items-center">
            <button
              onClick={onClose}
              className="text-sm text-gray-600 hover:text-gray-800 font-semibold transition-colors"
            >
              Close
            </button>
            <button
              onClick={handleDownload}
              className="bg-blue-600 text-white px-5 py-2 rounded-lg hover:bg-blue-700 text-sm font-semibold transition-colors"
            >
              Download
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default Drawer;