import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';

const CompanyLayout = () => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [landingPage, setLandingPage] = useState({});
  const [routes, setRoutes] = useState([]);
  const [links, setLinks] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost.com:5000';
  const companySlug = process.env.REACT_APP_COMPANY_SLUG || 'transporzuma'; // Slug fijo

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        setLoading(true);
        if (!companySlug) {
          throw new Error('El companySlug no está definido');
        }

        const companyResponse = await fetch(`${API_URL}/api/company-by-slug/${companySlug}`);
        if (!companyResponse.ok) {
          const errorText = await companyResponse.text();
          throw new Error(`Error al cargar la empresa (status ${companyResponse.status}): ${errorText}`);
        }
        const companyData = await companyResponse.json();
        setCompanyData(companyData);
        setCompanyId(companyData.company_id);

        // Resto del código sigue igual...
        const landingPageResponse = await fetch(`${API_URL}/api/landing-pages/${companyData.company_id}`);
        let landingPageData = {};
        if (landingPageResponse.ok) {
          landingPageData = await landingPageResponse.json();
        }
        setLandingPage(landingPageData);

        const routesResponse = await fetch(`${API_URL}/api/companies/${companyData.company_id}/routes`);
        let routesData = [];
        if (routesResponse.ok) {
          routesData = await routesResponse.json();
        }
        setRoutes(routesData);

        const linksData = {};
        for (const route of routesData) {
          const linksResponse = await fetch(`${API_URL}/api/routes/${route.route_id}/links`);
          if (linksResponse.ok) {
            const routeLinks = await linksResponse.json();
            linksData[route.route_id] = routeLinks;
          }
        }
        setLinks(linksData);
      } catch (error) {
        console.error('Error al cargar los datos de la empresa:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [companySlug, navigate]); // Dependencia sigue siendo companySlug, pero ahora es constante

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar companyData={companyData} landingPage={landingPage} companySlug={companySlug} />
      <main className="flex-grow">
        {loading && <div className="text-center py-12"><p>Cargando datos de la empresa...</p></div>}
        {error && (
          <div className="text-center py-4">
            <p className="text-red-500 text-lg">{error}</p>
            <p>Por favor, intenta de nuevo más tarde.</p>
          </div>
        )}
        <Outlet context={{ companyData, companySlug, companyId, landingPage, routes, links }} />
      </main>
      <Footer companySlug={companySlug} />
    </div>
  );
};

export default CompanyLayout;