// components/Reviews.js
import React from 'react';

const Reviews = () => {
  // Efecto parallax usando JavaScript
  const handleScroll = () => {
    const section = document.querySelector('#reviews-section');
    if (section) {
      const scrollPosition = window.scrollY;
      const offset = section.offsetTop;
      const parallaxEffect = (scrollPosition - offset) * 0.4; // Ajusta la velocidad del parallax
      section.style.backgroundPositionY = `${parallaxEffect}px`;
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section
      id="reviews-section"
      className="relative w-full h-[400px] bg-cover bg-center bg-no-repeat flex items-center justify-center"
      style={{
        backgroundImage: `url('https://old.transporzuma.com/wp-content/uploads/2024/01/47f77a5b-3362-4be8-bb9f-d73a0aaeaa55.jpg')`, // Imagen de fondo genérica (playa)
        backgroundAttachment: 'fixed', // Para el efecto parallax
      }}
    >
      {/* Overlay */}
      <div
        className="absolute inset-0"
        style={{
          backgroundColor: 'oklch(0.424 0.199 265.638)',
          opacity: 0.7, // Opacidad para que la imagen de fondo sea visible
        }}
      ></div>

      {/* Contenido */}
      <div className="relative z-10 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">Reviews</h2>
        <div className="flex items-center justify-center mb-4">
          <span className="text-5xl font-semibold">4.5</span>
          <div className="ml-3 flex">
            {[...Array(4)].map((_, i) => (
              <svg
                key={i}
                className="w-6 h-6 text-yellow-400 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
              </svg>
            ))}
            <svg
              className="w-6 h-6 text-yellow-400 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z M12 14.54L9.19 16.63 10 13.83l-2.46-2.14 3.27-.28L12 8l1.19 3.41 3.27.28-2.46 2.14.81 2.8L12 14.54z" />
            </svg>
          </div>
        </div>
        <p className="text-lg mb-6">
          Based on 240 reviews on bookaway.com
        </p>
        <button
          className="px-6 py-2 text-white font-semibold rounded-full shadow hover:brightness-110 transition-colors"
          style={{ backgroundColor: 'oklch(0.424 0.199 265.638)' }}
        >
          View All Reviews
        </button>
      </div>
    </section>
  );
};

export default Reviews;