// src/components/context/CompanyContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const { companySlug } = useParams(); // Obtiene el companySlug de la URL
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  const fetchCompanyData = useCallback(async () => {
    if (!companySlug) {
      setError('No se proporcionó un companySlug');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);
      console.log(`CompanyContext - Fetching company data for companySlug: ${companySlug}`);

      const response = await fetch(`${API_URL}/api/landing-page/${companySlug}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('CompanyContext - Fetch response status:', response.status);

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        console.error('CompanyContext - Response is not JSON:', text);
        throw new Error('La respuesta del servidor no es JSON');
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al obtener los datos de la compañía');
      }

      const data = await response.json();
      console.log('CompanyContext - Company data fetched successfully:', data);
      setCompanyData(data);
    } catch (err) {
      console.error('CompanyContext - Error fetching company data:', err.message);
      setError(err.message || 'Error al cargar los datos de la compañía');
    } finally {
      setLoading(false);
    }
  }, [companySlug, API_URL]);

  useEffect(() => {
    fetchCompanyData();
  }, [fetchCompanyData]);

  return (
    <CompanyContext.Provider
      value={{
        companySlug,
        companyId: companyData?.companyId || null,
        companyData,
        loading,
        error,
        fetchCompanyData,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};