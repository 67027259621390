import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const ThankYou = () => {
  const { state } = useLocation();
  const { orderId } = state || {};

  if (!orderId) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500 text-lg">Error: No order data provided</p>
        <p className="text-gray-500">Please complete your booking to see this page.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
      <div className="max-w-xl w-full bg-white shadow-md rounded-lg p-8 text-center">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Booking Confirmed</h1>
        <p className="text-gray-700 text-lg mb-4">
          Your reservation has been successfully created. QR tickets and your invoice have been generated.
        </p>
        <p className="text-gray-700 text-md mb-2">
          Please present the QR code to the driver before boarding. Arrive at least 15 minutes before the scheduled departure time.
        </p>
        <p className="text-gray-700 text-md mb-6">
          Log in to your account to download your tickets and manage your reservation.
        </p>
        <p className="text-gray-800 font-semibold mb-8">Thank you for choosing TransporZuma!</p>
        <Link
          to="/my-account"
          className="inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
        >
          View My Tickets
        </Link>
      </div>
    </div>
  );
};

export default ThankYou;