import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import RouteFilter from './RouteFilter';
import RouteInfoPanel from './RouteInfoPanel';

const ArchiveRoutes = () => {
  const { routes, links, companySlug } = useOutletContext();
  const [selectedFrom, setSelectedFrom] = useState('');
  const [selectedTo, setSelectedTo] = useState('');
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [pickupDropoff, setPickupDropoff] = useState({ pickup: null, dropoff: null }); // Estado para pickup y dropoff
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost.com:5000';

  // Generar todas las combinaciones de route y link
  const generateRouteLinks = () => {
    return routes.flatMap((route) =>
      links[route.route_id]?.map((link) => ({
        routeId: route.route_id,
        routeName: route.route_name,
        linkId: link.link_id,
        linkName: link.link_name,
        price: link.price || 'N/A',
      })) || []
    );
  };

  const routeLinks = generateRouteLinks();

  // Filtrar las rutas según los selects "From" y "To"
  const filteredRouteLinks = routeLinks.filter((item) => {
    const matchesFrom = selectedFrom ? String(item.routeId) === String(selectedFrom) : true;
    const matchesTo = selectedTo ? String(item.linkId) === String(selectedTo) : true;
    return matchesFrom && matchesTo;
  });

  // Fetch de horarios y pickup/dropoff cuando se selecciona una ruta
  useEffect(() => {
    if (!selectedRoute) {
      setSchedules([]);
      setPickupDropoff({ pickup: null, dropoff: null });
      return;
    }

    const fetchSchedules = async () => {
      try {
        const response = await fetch(`${API_URL}/api/schedules/${selectedRoute.linkId}`);
        if (!response.ok) {
          const text = await response.text();
          console.error('Response not OK (schedules):', response.status, text);
          throw new Error(`Error fetching schedules: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setSchedules(data);
      } catch (error) {
        console.error('Error fetching schedules:', error);
        setSchedules([]);
      }
    };

    const fetchPickupDropoff = async () => {
      if (!selectedRoute?.linkId) {
        console.warn('No linkId provided for fetching pickup/dropoff');
        setPickupDropoff({ pickup: null, dropoff: null });
        return;
      }
      try {
        console.log(`Fetching pickup/dropoff for linkId: ${selectedRoute.linkId}`);
        const response = await fetch(`${API_URL}/api/pickup-dropoff/${selectedRoute.linkId}`);
        if (!response.ok) {
          const text = await response.text();
          console.error('Response not OK (pickup/dropoff):', response.status, text);
          throw new Error(`Error fetching pickup/dropoff: ${response.status} ${response.statusText}`);
        }
        const result = await response.json();
        if (result.success && result.data) {
          const pickup = result.data.find((item) => item.type === 'pickup') || null;
          const dropoff = result.data.find((item) => item.type === 'dropoff') || null;
          setPickupDropoff({ pickup, dropoff });
        } else {
          console.warn(result.message);
          setPickupDropoff({ pickup: null, dropoff: null });
        }
      } catch (error) {
        console.error('Error fetching pickup/dropoff:', error);
        setPickupDropoff({ pickup: null, dropoff: null });
      }
    };

    fetchSchedules();
    fetchPickupDropoff();
  }, [selectedRoute, API_URL]);

  const handleSelect = (route) => {
    setSelectedRoute(route);
  };

  const handleClosePanel = () => {
    setSelectedRoute(null);
    setPickupDropoff({ pickup: null, dropoff: null }); // Limpiar pickupDropoff al cerrar
  };

  // Función para manejar los cambios en los filtros
  const handleFilterChange = ({ from, to }) => {
    setSelectedFrom(from);
    setSelectedTo(to);
  };

  return (
    <section className="w-full py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-4 text-gray-900">
          Available Routes
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Explore our existing routes and book your next trip with ease..
        </p>
        {/* Integrar el componente RouteFilter */}
        <RouteFilter
          routes={routes}
          links={links}
          onFilterChange={handleFilterChange}
          selectedFrom={selectedFrom}
          selectedTo={selectedTo}
        />
        {filteredRouteLinks.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredRouteLinks.map((item) => (
              <div
                key={`${item.routeId}-${item.linkId}`}
                className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col justify-between h-64"
              >
                <div>
                  <div className="flex justify-between items-start">
                    <h3 className="text-base font-semibold text-gray-900 mb-2">
                      {item.routeName}
                    </h3>
                    <div className="text-right ml-4">
                      <span className="text-[18px] font-bold" style={{ color: '#2E4A9B' }}>
                        ${item.price}
                      </span>
                      <p className="text-gray-600 text-sm">Desde/</p>
                    </div>
                  </div>
                  <div className="flex items-center mb-3">
                    <ArrowRightIcon className="h-5 w-5 text-gray-500 mr-2" />
                    <span className="text-gray-700 font-medium">
                      to {item.linkName}
                    </span>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => handleSelect(item)}
                    className="mt-4 px-4 py-2 text-white font-semibold rounded-full shadow hover:brightness-110 transition-colors w-full"
                    style={{ backgroundColor: 'oklch(0.424 0.199 265.638)' }}
                  >
                    Select
                  </button>
                  <p className="text-center text-gray-600 text-sm mt-2">
                    per person
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">
            {(selectedFrom || selectedTo) ? 'No routes match your selection.' : 'No routes available at the moment.'}
          </p>
        )}
        {/* Panel y overlay */}
        {selectedRoute && (
          <>
          <RouteInfoPanel
  route={selectedRoute}
  schedules={schedules}
  pickupDropoff={pickupDropoff}
  onClose={handleClosePanel}
  companySlug={companySlug} // 👈 Esto es lo que faltaba
/>
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={handleClosePanel}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default ArchiveRoutes;